import * as React from "react";

import Layout from "../layouts";

const CommentpolicyPage = (): JSX.Element => {

  return (
    <Layout>
      <div className="about-container">
        <p>
          Comments are welcomed and encouraged on this site, but there are some
          instances where comments will be edited or deleted. Comments are
          subject to the website's{" "}
          <a href="http://www.randomdatagenerator.net/legal/">terms of use</a>.
          Violations of this policy may result in comments being deleted and/or
          commenters being blocked from access to commenting or to the blog
          entirely.
        </p>
        <ul>
          <li>
            <strong>All Rights Reserved:</strong> The blog owner reserves the
            right to edit, delete, move, or mark as spam any and all comments.
            Blog owner has the right to block access to any one or group from
            commenting or from the entire blog.
          </li>
          <li>
            <strong>Comment Form Guidelines:</strong> The comment form must be
            filled in with a proper or legitimate sounding name and URL.
            Comments using keywords, spam or splog-like URLs, or suspicious
            information in the comment form will be deleted. Your comment will
            not be edited, it will simply be deleted in its entirety.
          </li>
          <li id="links">
            <strong>Links:</strong> If you are leaving a comment and want to
            point to a link on your own or someone else’s site that is relevant
            to the topic, please feel free to do so. Keep in mind, however, the
            link <strong>must</strong> be relevant and add value to the blog and
            its readers. This blog is set up to automatically hold any comment
            with more than one link in moderation, which may delay your comment
            from appearing.
          </li>
          <li>
            <strong>Thank You Comments:</strong> Superficial comments that
            appear to be an attempt to get a backlink or get past comment
            moderation will be deleted.
          </li>
          <li>
            <strong>Copyright/Plagiarism:</strong> If notice is received that a
            comment contains proprietary, copyrighted or plagiarized
            information, that comment will be deleted and the commenter may be
            blocked from further commenting.
          </li>
          <li>
            <strong>Email Privacy:</strong> Email addresses are required for
            commenting, and they are not published on the blog, nor shared. They
            may be used by the blog owner to privately contact the commenter.
          </li>
          <li>
            <strong>Commenter Privacy and Protection:</strong> Comments
            containing email addresses, physical mail addresses, phone numbers,
            and any private and personal information will be deleted as soon as
            possible to protect the privacy of the affected party. To prevent
            such editing, never share private information within blog comments.
          </li>
          <li>
            <strong>Attachments:</strong> If you want to post an image or a
            workbook, please upload it to a file sharing service such as Photobucket or SkyDrive
            and link to it in your comment. Keep in mind that your
            comment may be held if it contains <a href="#links">links</a>.{" "}
            <strong>Please do not post screenshots of VBA code</strong> — copy
            and paste the code into the comment directly.
          </li>
          <li>
            <strong>Language and Manners:</strong> Comments which include
            offensive or inappropriate language, or considered by the blog owner
            to be rude and offensive, will be deleted. Especially irritating are
            comments that disparage Microsoft (while asking for help with a
            Microsoft product). In the interest of fair play, no personal
            attacks are permitted in this blog's comments. You may question or
            argue the content, but not attack the blogger, nor any other
            commenters. Failure to respect fellow participants on this blog
            could result in removal and blocked access.
          </li>
          <li>
            <strong>What To Do If Your Comment Does Not Appear:</strong> Please
            be patient. Comments cannot always be approved right away. Please do
            not submit the same comment unless you think your original comment
            was not submitted correctly the first time.
          </li>
          <li>
            <strong>Multiple Comments on the same Post:</strong> Multiple
            related comments on the same blog post (usually one after the other)
            may be combined into a single comment.
          </li>
          <li>
            <strong>Irrelevant Comments:</strong> If a comment is irrelevant to
            a specific post, it may be moved to another post to which it is
            relevant, if possible. If no suitable post can be found, it may be
            deleted.
          </li>
          <li>
            <strong>Comment Spam:</strong> Any comment assumed to be possible
            comment spam will be deleted and marked as comment spam. In
            addition, it will be reported to services such as Project Honeypot,
            Botscout and/or Stop Forum Spam.
          </li>
          <li>
            <strong>Liability:</strong> All comments within this blog are the
            responsibility of the individual commenter. By submitting a comment
            on this blog, you agree that the comment content is your own, and to
            hold this site, Jimmy Peña, and all subsidiaries and representatives
            harmless from any and all repercussions, damages, or liability.
          </li>
          <li>
            <strong>Trackbacks Are Comments:</strong> All trackbacks will be
            treated inline with our Comments Policy.
          </li>
        </ul>
        <p>
          Remember, <strong>commenting is a privilege, not a right.</strong>
        </p>
        <p>All information on this page is subject to change without notice.</p>
      </div>
    </Layout>
  );
};

export default CommentpolicyPage;
